<template>
  <div>
    <b-alert
      v-if="isShowWarningQH"
      show
      variant="danger"
      class="m-25 fw-700 p-50 text-center"
    >
      "Vé đã mua hành lý 1 chặng, chặng còn lại phải mua gói hành lý khác gói đã mua trước đó, nếu cần mua gói tương tự vui lòng chat Booker."
    </b-alert>

    <div
      v-if="!dataMapAncillaryServices.length"
      class="px-1 pb-1"
    >
      <em>{{ $t('reservation.noAddons') }}</em>
    </div>
    <div
      v-else
      class="p-0"
    >
      <b-table
        centered
        bordered
        responsive
        small
        hover
        class="mb-0"
        thead-class="text-center fw-700 text-nowrap"
        tbody-class="text-center text-nowrap"
        show-empty
        :empty-text="$t('reservation.noAddons')"
        :fields="tableColumns"
        :items="dataMapAncillaryServices"
      >
        <template
          v-for="(column, indexColumn) in tableColumns"
          #[`head(${column})`]="data"
        >
          <span :key="indexColumn">
            {{ $t(`reservation.${data.column}`) }}
          </span>
        </template>

        <!-- <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{
              width: field.key === 'seat' ? '120px' : '180px',
              'max-width': field.key === 'seat' ? '120px' : '400px',
            }"
          >
        </template> -->

        <template #cell(passenger)="{item}">
          <small
            v-if="['1S', '1S_CTRL'].includes(reservationsData.source)"
            class="text-warning font-weight-bolder"
          >{{ item.passenger.paxId }}</small>
          <span>
            {{ item.passenger.lastName }} / {{ item.passenger.firstName }} {{ item.passenger.title || '' }}
          </span>
        </template>

        <template #cell(itinerary)="{item}">
          <div v-if="['TH'].includes(bookingSource)">
            {{ item.segments }}
          </div>
          <div v-else-if="!isEmpty(item.segments) && item.segments.length < 2">
            {{ item.segments[0]?.departure?.iataCode }} - {{ item.segments[0]?.arrival?.iataCode }}
          </div>
          <div v-else-if="!isEmpty(item.segments) && item.segments.length > 1">
            <span
              v-for="(segment, indexSegment) in item.segments"
              :key="indexSegment"
            >
              {{ (indexSegment === 0) ? `${segment?.departure?.iataCode}` : '' }}
              {{ (indexSegment !== 0) && (indexSegment !== item?.segments?.length - 1) ? ` - ${segment?.departure?.iataCode}` : ` - ${segment?.arrival?.iataCode}` }}
            </span>
          </div>
        </template>

        <template #cell(services)="{item}">
          <span>{{ $t(`reservation.${['BAGGAGE','SEAT'].includes(item.serviceType) ? item.serviceType.toLowerCase() : 'otherSsr' }`) }}</span>
        </template>

        <template #cell(amount)="{item}">
          <span v-if="item.serviceType === 'SEAT'">
            {{ item.pdcSeat }}
          </span>

          <div v-if="item.serviceType === 'BAGGAGE'">
            <span v-if="['VJ'].includes(item.airline)">
              {{ (resolveSsrNameByCode(item.serviceInfo.description) ) }}
              x{{ item.numberOfItems }}
            </span>
            <span v-else-if="['TR'].includes(reservationsData.source)">
              {{ item?.serviceInfo?.description || item.serviceCode }}
              x{{ item.numberOfItems }}
            </span>
            <span v-else>
              {{ (resolveSsrNameByCode([ 'VN1A_MT', 'VN1A', 'TH'].includes(reservationsData.source) ? item.serviceCode : item.serviceSubCode)) }}
              x{{ item.numberOfItems }}
            </span>
          </div>

          <div v-if="item.isOtherService">
            <span v-if="['VJ'].includes(reservationsData.source)">
              {{ (resolveSsrNameByCode(item.serviceInfo.description) ) }}
            </span>
            <span v-else-if="['TR'].includes(reservationsData.source)">
              {{ item?.serviceInfo?.description || item.serviceCode }}
            </span>
            <span v-else>
              {{ (resolveSsrNameByCode(item.serviceSubCode || item.serviceCode) ) }}
            </span>
          </div>
        </template>

        <template #cell(status)="{item}">
          <span
            v-if="['VN'].includes(item.airline)"
            class="font-weight-bolder font-small-4"
            :class="item.status === 'HI' ? 'text-success' : item.status === 'HD' ? 'text-warning' : 'text-dark'"
          >{{ STATUS_NAME_ANCILLARY[item.status] || item.status }}</span>
        </template>

        <template #cell(total)="{item}">
          <span class="fw-700">{{ formatCurrency(item.totalPrice) }}</span>
        </template>

        <template #cell(paymentStatus)="{ item }">
          <div>
            <span :class="`fw-700 ${item.isPaid ? 'text-success' : 'text-warning'}`">
              {{ item.isPaid ? 'Đã thanh toán' : 'Chưa thanh toán' }}
            </span>
          </div>
        </template>

        <template #cell(action)="{item}">
          <b-row
            v-if="!isHideFunctionButton"
            class="mx-0 d-flex-center gap-2"
            style="min-width: 100px;"
          >
            <b-button
              v-if="fnAncillary[item.serviceType] && fnAncillary[item.serviceType].edit && disableBagVj2k(item)"
              variant="flat-info"
              class="p-50 rounded-circle bg-light-info"
              @click="openModalUpdateBaggage(item)"
            >
              <feather-icon
                icon="Edit3Icon"
                size="18"
              />
            </b-button>

            <b-button
              v-if="
                item
                  && fnAncillary[['MEAL'].includes(item.serviceType) ? 'OTHER' : item.serviceType]
                  && fnAncillary[['MEAL'].includes(item.serviceType) ? 'OTHER' : item.serviceType].delete
              "
              variant="flat-danger"
              class="p-50 rounded-circle bg-light-danger"
              @click="handleRemoveSsrInLine(item)"
            >
              <feather-icon
                icon="MinusIcon"
                size="16"
              />
            </b-button>
          </b-row>
        </template>
      </b-table>

      <b-row class="d-flex justify-content-between align-items-end fw-700">
        <b-col
          v-if="isWarningWeightBaggageAK"
          class="my-50"
          cols="12"
          lg="8"
        >
          <b-alert
            show
            variant="danger"
            class="my-0 fw-700 mx-1 p-50 text-center"
            style="min-width: 150px"
          >
            Trọng lượng tối đa của hành lý là 32kg mỗi kiện
          </b-alert>
        </b-col>

        <b-col
          v-if="isShowWarningBaggageAddonVJ"
          class="my-50"
          cols="12"
          lg="8"
        >
          <b-alert
            show
            variant="danger"
            class="my-0 fw-700 mx-1 py-50 px-2 text-left"
            style="min-width: 150px"
          >
            Gỡ bỏ hành lý sau khi thanh toán có thể bị hãng truy thu. Đại lý vui lòng tự chịu trách nhiệm và cân nhắc trước khi thao tác.
          </b-alert>
        </b-col>

        <b-col class="d-flex align-items-center justify-content-end py-50">
          <div class="d-flex flex-nowrap mr-1">
            <span class="mr-1 font-medium-2 text-nowrap text-danger">{{ $t('flight.total') }}: <span class="text-20px"> {{ formatCurrency(totalDataMapAncillaryServices) }} </span> </span>

            <div class="text-nowrap mr-md-0 my-auto">
              <feather-icon
                class="text-warning mr-25"
                icon="InfoIcon"
              />
              <span class="text-nowrap text-italic text-warning font-small-3">{{ $t('flight.fareText') }}</span>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <ModalBaggageFirstHandle />

    <ModalBaggageUpdate
      v-if="dataListToUpdate"
      :reservations-data="reservationsData"
      :data-pax-in-trip="dataListToUpdate"
    />

    <ModalSeatDetails :reservations-data="reservationsData" />

    <ModalOtherFirstHandle :reservations-data="reservationsData" />
  </div>
</template>

<script>
import {
  BButton,
  BTable,
  BRow,
  BCol,
  BAlert,
} from 'bootstrap-vue'
import {
  computed, ref, toRefs, watch,
} from '@vue/composition-api'
import { isEmpty, sortBy, isEqual } from 'lodash-es'
import Vue from 'vue'

import { resolveSsrNameByCode } from '@/constants/selectOptions'
import { SOURCE_AK_ECONOMY_FARE } from '@/constants/flight'
import i18n from '@/libs/i18n'
import IAmPaymentPassword from '@/components/IAmPaymentPassword.vue'
import store from '@/store'

import { formatCurrency, dateTime, convertISODateTime } from '@core/utils/filter'
import { validatorPaymentPassword } from '@core/utils/validations/validators'

import useReservationHandle from '@reservation/useReservationHandle'
import useAncillaryServicesHandle from '@reservation/reservation-modify/components/detail/ancillary-services/useAncillaryServicesHandle'

export default {
  components: {
    BButton,
    BTable,
    BRow,
    BCol,
    BAlert,

    ModalBaggageFirstHandle: () => import('./baggage/modal-first-select-pax.vue'),
    ModalBaggageUpdate: () => import('./baggage/ModalBaggageUpdate.vue'),
    ModalSeatDetails: () => import('./seat/ModalSeatDetails.vue'),
    ModalOtherFirstHandle: () => import('./other/modal-first-select-pax.vue'),
  },
  props: {
    reservationsData: {
      type: Object,
      required: true,
    },
    isBookingOutSystem: {
      type: Boolean,
      default: false,
    },
    isHideFunctionButton: {
      type: Boolean,
      default: false,
    },
    canIssueLimit: {
      type: Boolean,
      default: false,
    },
    isLockedModify: {
      type: Boolean,
      default: false,
    },
    totalAncillaryServicePrice: {
      type: [Number, null],
      default: 0,
    },
  },
  setup(props, { root, emit }) {
    const {
      STATUS_NAME_ANCILLARY,
      modifyAncillary,
      modifyCancelSeat,
      hideFeature,
    } = useReservationHandle()

    const {
      fnAncillary,
      bookingSource,
      tableColumnsVNA,
      tableColumns: tableColumnsOther,
      delay,
      getAirlineBySource,
      toastError,
      isDevelopment,
    } = useAncillaryServicesHandle()

    const { reservationsData } = toRefs(props)
    const isDisableAddAncillaryQH = computed(() => ['QH', 'TH'].includes(reservationsData.value?.source) && !['PAID', 'PARTIALLY_PAID'].includes(reservationsData.value?.status))
    const discountInvalid = ref(false)
    const AK_BAGGAGE_CODE_WARNING = ['PBAF', 'PBAG', 'PBAH']

    const isWarningWeightBaggageAK = computed(() => {
      if (isEmpty(reservationsData.value?.ancillaryServices)) return false
      const isAK = ['AK'].includes(bookingSource.value)
      const isSsrBags = name => ['BAGGAGE'].includes(name)
      const isEconomyFare = segmentId => {
        if (isEmpty(segmentId)) return false
        const segmentData = reservationsData.value.itineraries.find(trip => trip && trip.some(segment => segmentId === segment.segmentId))
        if (isEmpty(segmentData)) return false
        const isItineraryKULDMK = ['KUL-DMK'].includes(`${segmentData[0].departure.iataCode}-${segmentData[0].arrival.iataCode}`)
        return isItineraryKULDMK && SOURCE_AK_ECONOMY_FARE.includes(segmentData[0]?.bookingClass)
      }
      return reservationsData.value.ancillaryServices?.some(item => isAK
      && isSsrBags(item?.serviceType)
      && isEconomyFare(item?.segmentIds?.[0])
      && AK_BAGGAGE_CODE_WARNING.includes(item.serviceCode))
    })

    // ANCHOR Edit Baggage
    function showEditBaggage() {
      if (isDisableAddAncillaryQH.value) {
        toastError({
          title: `${root.$te(`flight.airlines.${bookingSource.value}`) ? root.$t(`flight.airlines.${bookingSource.value}`) : bookingSource.value}: Vui lòng thanh toán trước khi thêm dịch vụ hãng!`,
        })
        return
      }
      if (reservationsData.value && isEmpty(reservationsData.value.paxLists.filter(p => p.paxType !== 'INFANT'))) {
        toastError({
          title: 'Mua thêm hành lý  chỉ áp dụng đối với mã đặt chỗ có hành khách người lớn hoặc trẻ em!',
        })
        return
      }
      if (reservationsData.value && isEmpty(reservationsData.value.itineraries)) {
        toastError({
          title: 'Không có hành trình trong mã đặt chỗ hiện tại!',
        })
        return
      }
      this.$bvModal.show('modal-modify-ancillary-baggage-select-pax')
    }

    // ANCHOR Edit Seat
    function showEditSeat() {
      if (isDisableAddAncillaryQH.value) {
        toastError({
          title: 'Bamboo Airways: Vui lòng thanh toán trước khi thêm dịch vụ hãng!',
        })
        return
      }
      this.$bvModal.show('modal-modify-ancillary-seat-details')
    }

    // ANCHOR Edit Other
    async function showEditOther() {
      if (isDisableAddAncillaryQH.value) {
        toastError({
          title: `${root.$te(`flight.airlines.${bookingSource.value}`) ? root.$t(`flight.airlines.${bookingSource.value}`) : bookingSource.value}: Vui lòng thanh toán trước khi thêm dịch vụ hãng!`,
        })
        return
      }
      this.$bvModal.show('modal-modify-ancillary-other-select-pax')
    }

    // lấy thông tin dịch vụ mua thêm từ bookingData
    // 1S, QH, VJ, VU
    const dataMapAncillaryServices = computed(() => {
      const result = []
      const {
        ancillaryServices, paxLists, itineraries, source,
      } = reservationsData.value

      if (isEmpty(itineraries) || isEmpty(ancillaryServices) || !['VN1A_MT', 'VN1A', '1S', '1S_CTRL', 'QH', 'VJ', 'VZ', 'VU', 'AK', 'TH', 'TR', 'F1', '1A'].includes(source)) return result
      // NOTE: Tránh lỗi gói addons không có segmentId trùng với segment trong mã đặt chỗ: itineraries.flat().map(s => s.devAddonsSegmentIndex).includes(item.segmentIds[0])
      // NOTE: QH chưa có thêm chuyến bay => mặc định devAddonsSegmentIndex aka segmentId vẫn đúng
      const getListBaggage = ancillaryServices.filter(item => item.serviceType === 'BAGGAGE' && itineraries.flat().map(segment => segment.devAddonsSegmentIndex).includes(item.segmentIds[0]))
      if (!isEmpty(getListBaggage)) {
        getListBaggage.forEach(item => {
          result.push({
            ...item,
            airline: getAirlineBySource(source),
            isOtherService: false,
            passenger: paxLists.find(p => p.paxId === item.paxId),
            segments: ['TH'].includes(source)
              ? item.segmentIds.join(', ')
              : ['AK', 'TR'].includes(source)
                ? itineraries.flatMap(trip => trip.map(seg => seg)).filter(s => item.segmentIds[0] === s.segmentId) // isEqual(sortBy(item.segmentIds), sortBy(trip.map(s => s.segmentId)))).flat()
                : itineraries.filter(trip => isEqual(sortBy(item.segmentIds), sortBy(trip.map(s => s.segmentId)))).flat(),
          })
        })
      }

      const getListSeat = ancillaryServices.filter(item => (item.serviceType === 'SEAT') && itineraries.flat().map(segment => segment.devAddonsSegmentIndex).includes(item.segmentIds[0]))
      if (!isEmpty(getListSeat)) {
        getListSeat.forEach(item => {
          result.push({
            ...item,
            airline: getAirlineBySource(source),
            isOtherService: false,
            passenger: paxLists.find(p => p.paxId === item.paxId),
            segments: [itineraries.flat().find(s => s.segmentId === item.segmentIds[0])],
            ...(['VU'].includes(bookingSource.value) && !item.pdcSeat && {
              pdcSeat: paxLists.find(p => p.paxId === item.paxId)?.seats?.find(s => (s?.paxId === item?.paxId) && (s?.segmentId === item?.segmentIds[0]))?.seatNumbers || '',
            }),
          })
        })
      }

      const getListOther = ancillaryServices.filter(item => !['BAGGAGE', 'SEAT'].includes(item.serviceType) && itineraries.flat().map(s => s.segmentId).includes(item.segmentIds[0]))
      if (!isEmpty(getListOther)) {
        getListOther.forEach(item => {
          result.push({
            ...item,
            airline: getAirlineBySource(source),
            isOtherService: true,
            passenger: paxLists.find(p => p.paxId === item.paxId),
            segments: ['TH'].includes(source) ? item.segmentIds.join(', ') : [itineraries.flat().find(s => s.segmentId === item.segmentIds[0])],
          })
        })
      }
      return isEmpty(result) ? result : result.sort((a, b) => a.paxId.localeCompare(b.paxId))
    })

    // Tổng tiền dịch vụ
    const totalDataMapAncillaryServices = ref(0)
    function updateTotalDataMapAncillaryServices() {
      const total = dataMapAncillaryServices.value.reduce((a, c) => a + c.totalPrice, 0)
      emit('update:total-ancillary-service-price', total)
      totalDataMapAncillaryServices.value = total
    }

    watch(dataMapAncillaryServices, () => {
      updateTotalDataMapAncillaryServices()
    }, { immediate: true, deep: true })

    // ======= UPDATE SSR ==========
    const dataListToUpdate = ref(null)
    const loadingNextButton = ref(false)

    async function openModalUpdateBaggage(item) {
      const ssrType = 'BAGGAGE'

      const ssrItems = reservationsData.value.ancillaryServices.filter(anci => {
        const nameServiceType = anci.serviceType === ssrType
        const ownPax = anci.paxId === item.passenger.paxId
        const ownSegment = anci.segmentIds.some(as => item.segments.some(is => is.segmentId === as))
        return nameServiceType && ownPax && ownSegment
      })

      dataListToUpdate.value = {
        passenger: item.passenger,
        segments: item.segments,
        ssrItems,
      }

      loadingNextButton.value = true

      try {
        await delay(400)
        this.$bvModal.show('modal-reservation-modify-update-baggage')
      } catch (error) {
        console.error('Error openModalBaggageSecond::', error)
      } finally {
        await delay(200)
        loadingNextButton.value = false
      }
    }

    function disableBagVj2k(item) {
      if (!['VJ'].includes(bookingSource.value)) return true

      const ssrType = item.serviceType
      const ssrItems = reservationsData.value.ancillaryServices.filter(anci => {
        const nameServiceType = anci.serviceType === ssrType
        const ownPax = anci.paxId === item.passenger.paxId
        const ownSegment = anci.segmentIds.some(as => item.segments.some(is => is.segmentId === as))
        return nameServiceType && ownPax && ownSegment
      })
      const isEdit = ((ssrItems.length === 1) && !['CBXS'].includes(ssrItems[0].serviceSubCode)) || !['CBXS'].includes(item.serviceSubCode)
      return isEdit
    }

    // ======= DELETE SSR ==========
    function getItineraries(segments, passenger) {
      const result = []

      if (!['VJ', 'VN1A', 'VN1A_MT', 'AK'].includes(bookingSource.value)) {
        toastError({
          title: 'Chưa hoạt động với hãng hàng không hiện tại!',
        })
      } else {
        segments.forEach(seg => {
          result.push({
            airline: seg.airline,
            departure: seg.departure.iataCode,
            arrival: seg.arrival.iataCode,
            departureDate: convertISODateTime(
              seg.departure.at,
              seg.departure.timeZone,
            ).ISOdatetime,
            arrivalDate: convertISODateTime(
              seg.arrival.at,
              seg.arrival.timeZone,
            ).ISOdatetime,
            flightNumber: seg.flightNumber,
            segmentId: seg.segmentId,
            fareBasisCode:
              passenger?.fareBasisCode?.split(' | ')[Number(seg.segmentId) - 1]
              || '',
            bookingClass: seg.bookingClass || '',
            groupClass: seg.groupClass || '',
            bookingStatus: seg?.bookingStatus || seg?.status,
            itineraryId: seg?.itineraryId || '',
          })
        })
      }
      return result
    }

    const isShowWarningBaggageAddonVJ = computed(() => ['VJ'].includes(bookingSource.value)
      && reservationsData.value?.status.includes('PAID')
      && reservationsData.value?.ancillaryServices.find(bag => bag.serviceType === 'BAGGAGE'))

    const getMeEnablePaymentPassword = computed(() => store.getters['userStore/getMeEnablePaymentPassword'])

    function handleRemoveSsrInLine(item) {
      const isPaidBooking = ['PAID'].includes(props.reservationsData.status)

      if (['SEAT'].includes(item.serviceType) && !['VN1A', 'VN1A_MT'].includes(bookingSource.value)) {
        const payload = {
          paxId: item.paxId,
          segmentId: item.segmentIds[0],
          seatNumber: item.pdcSeat,
        }

        const titleMsg = () => this.$createElement('div', {
          domProps: {
            innerHTML: `
              Xác nhận huỷ chỗ ngồi: 
              <span class="text-warning font-weight-bolder">${item.pdcSeat}</span> ?
            `,
          },
        })

        this.$bvModal
          .msgBoxConfirm(titleMsg(), {
            title: this.$t('modal.confirm'),
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Huỷ chỗ ngồi',
            cancelTitle: this.$t('reservation.back'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: true,
            centered: true,
          })
          .then(value => {
            if (value) {
              this.$bvModal.show('modal-api-loading')

              modifyCancelSeat(reservationsData.value, payload)
                .then(() => {
                // store.dispatch('app-reservation/removeSeatMapData')
                })
                .finally(() => {
                  this.$bvModal.hide('modal-api-loading')
                })
            }
          })
      } else {
        const { segments, passenger } = item

        const payload = [
          {
            quantity: ['VJ'].includes(bookingSource.value) ? 1 : item.numberOfItems,
            operation: 'DELETE',
            id: item.id,
            serviceType: item?.serviceType || null,
            ancillaryValue: item?.serviceSubCode || null,
            ancillaryCode: item?.serviceCode || null,
            ancillaryType: item?.ancillaryType || item?.serviceType || undefined,
            paxInfo: {
              type: passenger.paxType,
              paxId: passenger.paxId,
              firstName: passenger.firstName,
              lastName: passenger.lastName,
              nameId: passenger?.nameId || undefined,
            },
            itineraries: getItineraries(segments, passenger),
            total: 1,
          },
        ]

        const titleMsg = () => this.$createElement('div', {
          domProps: {
            innerHTML: `
              <div>Xác nhận xoá ${['SEAT'].includes(item.serviceType) ? 'ghế' : 'gói/dịch vụ'} đã chọn?</div>
              ${isShowWarningBaggageAddonVJ.value ? '<div class="mt-50 alert p-50 mb-0 alert-danger">Gỡ bỏ hành lý sau khi thanh toán có thể bị hãng truy thu. Đại lý vui lòng tự chịu trách nhiệm và cân nhắc trước khi thao tác.</div>' : ''}
            `,
          },
        })
        this.$bvModal
          .msgBoxConfirm(titleMsg(), {
            title: this.$t('modal.confirm'),
            size: 'md',
            okVariant: 'danger',
            okTitle: 'Xoá',
            cancelTitle: 'Quay lại',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: true,
            centered: true,
          })
          .then(async value => {
            if (value) {
              let paymentPassword
              if (getMeEnablePaymentPassword.value && isPaidBooking) {
                paymentPassword = await Vue.swal({
                  title: this.$t('myAccount.paymentPassword.title'),
                  html: '<div id="i-am-payment-password"></div>',
                  didOpen: () => {
                    new Vue({
                      render: h => h(IAmPaymentPassword, { props: { typePaymentPassword: 'off' } }),
                      i18n,
                    }).$mount('#i-am-payment-password')
                  },
                  focusConfirm: false,
                  allowOutsideClick: true,
                  preConfirm: () => new Promise(resolve => {
                    const pwd = document.getElementById('swal-input-payment-password-1').value
                    if (!validatorPaymentPassword(pwd)) {
                      Vue.swal.showValidationMessage(this.$t('myAccount.paymentPassword.errorInput'))
                    } else { resolve(pwd) }
                  }),
                  showCancelButton: true,
                  confirmButtonText: this.$t('confirmation'),
                  cancelButtonText: this.$t('cancel'),
                  buttonsStyling: true,
                  customClass: {
                    confirmButton: 'btn btn-gradient',
                    cancelButton: 'btn btn-danger mr-1',
                  },
                  reverseButtons: true,
                })
                if (paymentPassword.isDenied || paymentPassword.isDismissed) {
                  return
                }
              }

              const paymentData = {
                isPayNow: isPaidBooking,
                paymentPassword: paymentPassword?.value || '',
              }

              this.$bvModal.show('modal-api-loading')
              modifyAncillary(props.reservationsData, payload, paymentData)
                .then(() => {
                  // this.$bvModal.hide('modal-reservation-modify-update-baggage')
                })
                .finally(() => this.$bvModal.hide('modal-api-loading'))
            }
          })
      }
    }

    const tableColumns = computed(() => {
      const cols = ['VN1A', 'VN1A_MT'].includes(props.reservationsData?.source) ? tableColumnsVNA : tableColumnsOther
      return hideFeature.value ? cols.filter(item => item !== 'action') : cols
    })

    const isShowWarningQH = computed(() => ['QH'].includes(props.reservationsData?.source) && ['RT'].includes(props.reservationsData?.flightType))

    return {
      fnAncillary,
      bookingSource,
      STATUS_NAME_ANCILLARY,
      isEmpty,
      tableColumns,
      isWarningWeightBaggageAK,

      dataMapAncillaryServices,
      dateTime,
      formatCurrency,
      resolveSsrNameByCode,

      showEditBaggage,
      showEditSeat,
      showEditOther,

      dataListToUpdate,
      disableBagVj2k,
      handleRemoveSsrInLine,
      openModalUpdateBaggage,
      totalDataMapAncillaryServices,

      isShowWarningQH,
      isShowWarningBaggageAddonVJ,
      isDevelopment,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables/_variables';
.bg-light-info {
  border: 1px solid transparent;
  &:hover{
    border: 1px solid $info;
  }
}

.bg-light-danger {
  border: 1px solid transparent;
  &:hover{
    border: 1px solid $danger;
  }
}
</style>
