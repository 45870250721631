<!-- NOTE:
  1. Chọn hành trình, hành khách => tính giá (chưa lưu) : modal-recalculate-price
  2. Hiển thị giá: modal-recalculate-show-price
  3. Tích chọn lưu giá => submit => lưu giá mới vào booking
-->
<template>
  <div>
    <!-- SECTION modal-recalculate-price -->
    <BModal
      id="modal-recalculate-price"
      :title="$t('reservation.recalculatePriceFlight')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      no-close-on-backdrop
      size="lg"
      @show="handleShowModal"
      @hide="handleHideModal"
    >
      <!-- ANCHOR Itineraries -->
      <b-card body-class="py-1">
        <div class="text-heading-5 mb-25">
          {{ $t('reservation.selectItineraryToRecalculatePrice') }}
        </div>
        <b-form-group class="mb-0">
          <template #label>
            <b-form-checkbox
              v-if="getBookingData.itineraries.flat().length > 1"
              v-model="itinerariesAllSelected"
              aria-describedby="itineraries"
              aria-controls="itineraries"
              :disabled="isDisableCheckAllTrip(getBookingData.itineraries)"
              @change="itinerariesToggleAll"
            >
              <div class="text-body-2">
                {{ itinerariesAllSelected ? $t('reservation.unselectAll') : $t('reservation.selectAll') }}
              </div>
            </b-form-checkbox>
          </template>
          <template #default="{ ariaDescribedby }">
            <b-form-checkbox-group
              v-model="indexSelectedFlightToRecalculatePrice"
              :aria-describedby="ariaDescribedby"
              stacked
              name="itineraries"
              class="mb-1"
            >
              <div
                v-for="(itinerary, indexItinerary) in getBookingData.itineraries"
                :key="indexItinerary"
                class="d-flex flex-column"
              >
                <div
                  v-for="(segment, indexSegment) in itinerary"
                  :key="indexSegment"
                >
                  <b-form-checkbox
                    :value="segment.segmentId"
                    :disabled="['VN1A'].includes(getBookingData.source) || isDisabledHLSegment(segment)"
                  >
                    <div class="d-flex align-items-center">
                      <div class="mr-50 text-heading-4">
                        {{ convertShortTrip(segment) }}
                      </div>
                    </div>
                  </b-form-checkbox>
                </div>
              </div>
            </b-form-checkbox-group>
          </template>
        </b-form-group>
      </b-card>

      <!-- ANCHOR - Passenger -->
      <b-card no-body>
        <b-table
          bordered
          centered
          responsive
          class="mb-0"
          thead-class="text-dark text-center text-nowrap"
          tbody-class="text-dark text-center text-nowrap"
          :fields="paxListsColumns"
          :items="paxListsData"
        >
          <template
            v-for="(column, index) in paxListsColumns"
            #[`head(${column.key})`]="data"
          >
            <div
              :key="index"
              class="text-dark"
            >
              <span v-if="data.column === 'checkbox'">
                <!-- :indeterminate="true" -->
                <b-form-checkbox
                  v-model="allPaxsSelected"
                  class="mr-1 pl-0"
                  inline
                  :disabled="['VN1A'].includes(getBookingData.source)"
                  @change="handlePaxsSelectAll(allPaxsSelected)"
                />
              </span>

              <span
                v-else
                class="text-nowrap"
              >
                {{ $t(`reservation.${data.column}`) }}
              </span>
            </div>
          </template>

          <template #cell(checkbox)="{ item }">
            <b-form-checkbox
              class="p-0"
              name="check-box"
              inline
              :disabled="['VN1A'].includes(getBookingData.source)"
              :checked="isChecked(item.paxId)"
              @change="chooseItem(item.paxId)"
            />
          </template>

          <template #cell(pax_id)="data">
            <div class="text-center text-truncate">
              <!-- eslint-disable-next-line vue/no-parsing-error -->
              {{ (data.item.paxId.length <= 6 ) ? data.item.paxId : data.index + 1 }}
            </div>
          </template>

          <template #cell(pax_type)="{ item }">
            <div class="custom-v-select-pax-type text-center">
              <v-select
                id="honorific"
                ref="vSelectPaxTypeRef"
                v-model="item.paxType"
                :append-to-body="true"
                :calculate-position="withPopper"
                :options="['ADULT', 'CHILD']"
                label="text"
                :disabled="['VN1A','1G'].includes(getBookingData.source)
                  || (['INFANT'].includes(item.paxType) ? true : false)
                "
                :clearable="false"
              >
                <template #option="data">
                  <span class="text-uppercase">
                    {{ $t(`reservation.${data.text}`) }}
                  </span>
                </template>

                <template #selected-option="data">
                  <span class="text-uppercase">
                    {{ $t(`reservation.${data.text}`) }}
                  </span>
                </template>
              </v-select>
            </div>
          </template>

          <template #cell(full_name)="{ item }">
            <div class="text-center">
              {{ `${item.lastName} / ${item.firstName}` }}
            </div>
          </template>
        </b-table>
      </b-card>

      <!-- ANCHOR - Airlines -->
      <b-card no-body>
        <b-row class="mx-0">
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group label-for="platingAirline">
              <template #label>
                <div class="d-flex justify-content-start gap-1">
                  <span class="text-nowrap">Airline</span>
                  <span
                    v-if="bookingData.source.includes('1S') || bookingData.source.includes('1G')"
                    class="text-danger"
                  >(*)</span>
                </div>
              </template>

              <b-form-input
                id="platingAirline"
                v-model="platingAirline"
                :state="!isErrorPlatingAirlineRequired ? null : false"
                :disabled="bookingData.source.includes('1S')"
                :placeholder="$t('flight.placeholderInput')"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
            class=""
          >
            <b-form-group label-for="Tour-Code">
              <template #label>
                <span class="text-nowrap">Tour Code</span>
              </template>

              <b-form-input
                id="Tour-Code"
                v-model="tourCode"
                :placeholder="$t('flight.placeholderInput')"
                :formatter="upperCaseFormatter"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group label-for="Account-Code">
              <template #label>
                <span class="text-nowrap">Account Code</span>
              </template>

              <b-form-input
                id="Account-Code"
                v-model="accountCode"
                :formatter="upperCaseFormatter"
                :placeholder="$t('flight.placeholderInput')"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <!-- ANCHOR - Retain and BargainFinder-->
      <b-card
        v-if="getBookingData.source.includes('1S') && canPriceBargainFinder"
        body-class="py-1"
      >
        <div class="margin-checkbox-label d-flex-center justify-content-around gap-2 text-center">
          <!-- <b-form-checkbox
              v-model="isRetain"
              name="retain-checkbox"
            >
              <h5 class="text-dark mb-0">
                {{ $t('reservation.retain') }}
              </h5>
            </b-form-checkbox> -->
          <b-form-checkbox
            v-model="isBargainFinder"
            name="retain-checkbox"
          >
            <h5 class="text-dark mb-0">
              {{ $t('reservation.bargainFinder') }}
            </h5>
          </b-form-checkbox>
        </div>
      </b-card>

      <template #modal-footer>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="center rounded-pill width-100"
          @click="handleHideModal"
        >
          {{ $t('reservation.close') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient mt-lg-25  border-0"
          :disabled="!indexSelectedFlightToRecalculatePrice.length || isErrorPlatingAirlineRequired"
          pill
          @click="submitRecalculatePrice"
        >
          <span class="align-middle">{{ $t('reservation.confirm') }}</span>
        </b-button>
      </template>
    </BModal>
    <!-- !SECTION -->

    <ModalRecalculateShowPrice
      :new-price="newPrice"
      :get-booking-data="getBookingData"
      :loading="showCalculatePriceLoading"
      :conversion-timeout="conversionTimeout"
      :conversion-id="conversionId"
      @save-price="submitSaveRecalculatePrice"
      @cleanAirPricingConversion="cleanConversion"
    />
  </div>
</template>

<script>
import {
  BModal, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BButton, BTable, BCard, BFormInput, BCol, BRow,
} from 'bootstrap-vue'
import {
  ref, watch, toRefs, computed,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import vSelect from 'vue-select'
import moment from 'moment'
import { createPopper } from '@popperjs/core'
import Vue from 'vue'

import { formatCurrency, convertISODateTime, convertShortTrip } from '@/@core/utils/filter'
import { getUserData } from '@/api/auth/utils'
import { resolveTitle } from '@/constants/selectOptions'
import store from '@/store'

import { upperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BButton,
    BTable,
    BCard,
    BFormInput,
    BCol,
    BRow,

    vSelect,
    ModalRecalculateShowPrice: () => import('./ModalRecalculateShowPrice.vue'),
  },
  props: {
    bookingData: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    withPopper(dropdownList, component, { width }) {
      const dropdownMaxWidth = '170px'
      dropdownList.style.border = '1px solid #b8c2cc'
      dropdownList.style.maxWidth = dropdownMaxWidth
      dropdownList.style.zIndex = '10000'
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [],
      })
      return () => popper.destroy()
    },
  },
  setup(props, { root }) {
    const { calculatePriceBooking, cleanAirPricingConversion } = useReservationHandle()
    const { toastSuccess, toastError } = useToast()

    const getBookingData = toRefs(props).bookingData
    const indexSelectedFlightToRecalculatePrice = ref([])
    // const indexSelectedFlightToRecalculatePriceComputed = computed(() => indexSelectedFlightToRecalculatePrice.value.sort())
    const isRetain = ref(false)
    const isBargainFinder = ref(false)
    const conversionId = ref()
    const conversionTimeout = ref()
    const newPrice = ref()
    const intervalId = ref() // for interval conversionTimeout

    const paxListsData = ref(cloneDeep(props.bookingData.paxLists))
    const selectedIds = ref(paxListsData.value.map(item => item.paxId))
    const tourCode = ref('')
    const accountCode = ref('')
    const platingAirline = ref('')
    const allPaxsSelected = ref(false)
    const vSelectPaxTypeRef = ref()

    watch(() => selectedIds.value, listSelected => {
      if (listSelected.length === paxListsData.value.length) {
        allPaxsSelected.value = true
      } else {
        allPaxsSelected.value = false
      }
    }, { immediate: true })

    const isErrorPlatingAirlineRequired = computed(() => {
      if (!platingAirline.value && (props.bookingData.source.includes('1S') || props.bookingData.source.includes('1G'))) {
        return true
      }
      return false
    })

    function handleShowModal() {
      if (intervalId.value) {
        clearInterval(intervalId.value)
      }
      intervalId.value = null
      tourCode.value = ''
      accountCode.value = ''
      platingAirline.value = ''
      isBargainFinder.value = false

      if (props.bookingData && props.bookingData.source.includes('1S')) {
        platingAirline.value = 'VN'
      } else if (props.bookingData && props.bookingData.source.includes('1G')) {
        platingAirline.value = props.bookingData.platingAirline
      }

      indexSelectedFlightToRecalculatePrice.value = []
      paxListsData.value = cloneDeep(props.bookingData.paxLists)
      selectedIds.value = paxListsData.value.map(item => item.paxId)

      isRetain.value = false
      newPrice.value = null
      conversionId.value = null
      conversionTimeout.value = null

      if (['VN1A'].includes(getBookingData.value.source)) {
        indexSelectedFlightToRecalculatePrice.value = getBookingData.value.itineraries.flat().map(item => item.segmentId).slice()
        selectedIds.value = getBookingData.value.paxLists.map(item => item.paxId)
      }
    }

    function getPassengerBirthday(passenger) {
      if (!passenger.birthday) {
        if (passenger.paxType === 'CHILD') {
          const currentDate = moment()
          const fiveYearsAgo = currentDate.subtract(5, 'years')
          return fiveYearsAgo.toISOString()
        }
        if (passenger.paxType === 'INFANT') {
          const currentDate = moment()
          const oneYearAgo = currentDate.subtract(1, 'years')
          return oneYearAgo.toISOString()
        }
        return passenger.birthday
      }

      return passenger.birthday
    }

    const prePayload = ref()
    async function submitRecalculatePrice(bvModalEvent) {
      bvModalEvent.preventDefault()

      const paxListArr = paxListsData.value.filter(item => selectedIds.value.includes(item.paxId))
      prePayload.value = null
      const payload = {
        contact: getUserData().employeeData.id,
        source: getBookingData.value.source,
        agencyCode: getBookingData.value.agency,
        paxCode: getBookingData.value.paxCode || '',
        pnrNumber: getBookingData.value.bookingCode,
        tourCode: tourCode?.value ? (tourCode?.value).toUpperCase() : '',
        accountCode: accountCode?.value ? (accountCode?.value).toUpperCase() : '',
        platingAirline: platingAirline.value,
        retain: isRetain.value,
        bargainFinder: isBargainFinder.value,
        paxList: paxListArr.map(passenger => {
          if (passenger.paxType === 'CHILD') {
            passenger.birthday = new Date(new Date().setFullYear(new Date().getFullYear() - 8))
          }
          return {
            paxId: passenger.paxId,
            seats: passenger.seats || [],
            title: passenger.title || resolveTitle(passenger.firstName.split(' ')[passenger.firstName.split(' ').length - 1]),
            gender: passenger.gender,
            eticket: passenger.eticket || [],
            paxType: passenger.paxType,
            PaxDocuments: ['VN1A'].includes(getBookingData.value.source) ? passenger?.document?.filter(d => ![''].includes(d.number)) : passenger?.document || [],
            lastName: passenger.lastName,
            firstName: passenger.firstName,
            // birthday: passenger.birthday || null,
            birthday: getPassengerBirthday(passenger) || null,
            bagCheckin: passenger.bagCheckin || [],
            parentPaxId: passenger.parentPaxId,
            pricingInfo: passenger.pricingInfo,
            travelFlyer: passenger.travelFlyer || '',
          }
        }),
        itineraries: indexSelectedFlightToRecalculatePrice.value.map(itinerarySegmentId => {
          const itinerary = getBookingData.value.itineraries.flat().find(item => item.segmentId === itinerarySegmentId)
          return {
            source: getBookingData.value.source,
            airline: itinerary.airline,
            clientId: getBookingData.value.clientId || '',
            bookingKey: getBookingData.value.bookingKey || '',
            fareBreakdowns: [
              {
                paxType: 'ADULT',
                netFare: 0,
                tax: 0,
                total: 0,
                fareValue: getBookingData.value?.fareValue || itinerary?.fareValue || '',
              },
            ],
            segments: [{
              leg: itinerary.leg,
              airline: itinerary.airline,
              departure: itinerary.departure.iataCode,
              arrival: itinerary.arrival.iataCode,
              departureTime: convertISODateTime(itinerary.departure.at, itinerary.departure.timeZone).ISOdatetime,
              arrivalTime: convertISODateTime(itinerary.arrival.at, itinerary.arrival.timeZone).ISOdatetime,
              flightNumber: itinerary.flightNumber,
              fareType: itinerary.fareType || '',
              fareBasisCode: itinerary.fareBasisCode || '',
              bookingClass: itinerary.bookingClass || '',
              groupClass: itinerary.groupClass || '',
              marriageGrp: itinerary.marriageGrp || '',
              segmentValue: itinerary.segmentValue || '',
              segmentId: itinerary.segmentId,
            }],
          }
        })
          .filter(i => !!i),
      }
      prePayload.value = cloneDeep(payload)

      if (!isEmpty(payload.itineraries)) {
        this.$bvModal.show('modal-api-loading')
        calculatePriceBooking(payload)
          .then(res => {
            toastSuccess({
              title: 'messagesList.success',
              content: 'messagesList.reservation.recalculatePriceSuccess',
            })
            if (!isRetain.value) {
              newPrice.value = res.priceQuotes
              conversionId.value = res.conversionID
              conversionTimeout.value = res.conversionTimeout
              this.$bvModal.show('modal-recalculate-show-price')
            }
          })
          .catch(() => {
            toastError({
              title: 'messagesList.error',
              content: 'messagesList.reservation.recalculatePriceError',
            })
          })
          .finally(() => {
            this.$bvModal.hide('modal-api-loading')
            this.$bvModal.hide('modal-recalculate-price')
          })
        // show modal confirm to modify
        // const titleMsg = () => this.$createElement('div', {
        //   domProps: { innerHTML: this.$t('reservation.payments.confirmReCalcPrice') },
        // })
        // this.$bvModal
        //   .msgBoxConfirm(titleMsg(), {
        //     title: this.$t('modal.confirm'),
        //     size: 'sm',
        //     okVariant: 'info',
        //     okTitle: this.$t('reservation.recalculatePriceFlight'),
        //     cancelTitle: this.$t('reservation.back'),
        //     cancelVariant: 'outline-secondary',
        //     hideHeaderClose: true,
        //     centered: true,
        //   })
        //   .then(value => {
        //     if (value) {
        //       this.$bvModal.show('modal-api-loading')
        //       calculatePriceBooking(payload)
        //         .then(res => {
        //           toastSuccess({
        //             title: 'messagesList.success',
        //             content: 'messagesList.reservation.recalculatePriceSuccess',
        //           })
        //           if (!isRetain.value) {
        //             newPrice.value = res.priceQuotes
        //             conversionId.value = res.conversionID
        //             this.$bvModal.show('modal-recalculate-show-price')
        //           }
        //         })
        //         .catch(() => {
        //           toastError({
        //             title: 'messagesList.error',
        //             content: 'messagesList.reservation.recalculatePriceError',
        //           })
        //         })
        //         .finally(() => {
        //           this.$bvModal.hide('modal-api-loading')
        //           this.$bvModal.hide('modal-recalculate-price')
        //         })
        //     }
        //   })
      }
    }
    const showCalculatePriceLoading = ref(false)
    async function submitSaveRecalculatePrice() {
      if (prePayload.value) {
        showCalculatePriceLoading.value = true
        calculatePriceBooking({
          ...prePayload.value,
          retain: true,
          conversionId: conversionId.value,
        })
          .then(() => {
            toastSuccess({
              title: 'messagesList.success',
              content: 'messagesList.reservation.saveRecalculatePriceSuccess',
            })
          })
          .catch(() => {
            toastError({
              title: 'messagesList.error',
              content: 'messagesList.reservation.saveRecalculatePriceError',
            })
          })
          .finally(() => {
            showCalculatePriceLoading.value = false
            this.$bvModal.hide('modal-recalculate-show-price')
          })
      }
    }

    // ANCHOR SELECT ALL
    const itinerariesAllSelected = ref(false)

    function itinerariesToggleAll(checked) {
      indexSelectedFlightToRecalculatePrice.value = checked
        ? getBookingData.value.itineraries.flat().map(item => item.segmentId).slice()
        : []
    }

    watch(indexSelectedFlightToRecalculatePrice, newValue => {
      if (newValue.length === 0) {
        itinerariesAllSelected.value = false
      } else if (newValue.length === getBookingData.value.itineraries.flat().map((item, index) => index).length) {
        itinerariesAllSelected.value = true
      } else {
        itinerariesAllSelected.value = false
      }
    })

    function setArray(array, id) {
      const index = array ? array.findIndex(element => element === id) : -1
      // eslint-disable-next-line no-unused-expressions
      index >= 0 ? array.splice(index, 1) : array.push(id)

      return array
    }

    function chooseItem(id) {
      selectedIds.value = setArray(selectedIds.value, id)
    }

    function isChecked(id) {
      return selectedIds.value.includes(id)
    }

    function handlePaxsSelectAll(value) {
      if (value) {
        selectedIds.value = paxListsData.value.map(item => item.paxId)
      } else {
        selectedIds.value = []
      }
    }

    const paxListsColumns = [
      { label: '', key: 'checkbox' },
      { label: 'paxId', key: 'pax_id' },
      { label: 'paxType', key: 'pax_type' },
      { label: 'fullName', key: 'full_name' },
    ]
    watch(() => isBargainFinder.value, newValue => {
      if (newValue) {
        allPaxsSelected.value = true
        handlePaxsSelectAll(allPaxsSelected.value)
      }
    })

    function handleHideModal() {
      const modalShow = Array.from(window.document.querySelectorAll('.modal.show'))
        .map(el => el.id).filter(id => id === 'modal-recalculate-price')

      modalShow.forEach(id => {
        root.$root.$emit('bv::hide::modal', id)
      })
    }

    function cleanConversion() {
      clearInterval(intervalId.value)
      intervalId.value = null
      if (conversionId.value) {
        cleanAirPricingConversion({ source: getBookingData.value.source, conversionID: conversionId.value })
        conversionId.value = null
      }
    }

    // eslint-disable-next-line no-unused-vars
    watch(conversionTimeout, (newValue, oldValue) => {
      if (newValue > 0 && !oldValue) {
        intervalId.value = setInterval(() => {
          conversionTimeout.value -= 1000
        }, 1000)
      } else if (newValue <= 0 && intervalId.value) {
        cleanConversion()
        Vue.swal({
          title: 'Cảnh báo!',
          text: 'Giá đã hết thời hạn, vui lòng tính lại giá.',
          icon: 'warning',
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: 'Đóng',
          customClass: {
            confirmButton: 'btn btn-outline-warning',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            const modalShow = Array.from(window.document.querySelectorAll('.modal.show')).map(el => el.id)
            modalShow.forEach(id => {
              root.$root.$emit('bv::hide::modal', id)
            })
          }
        })
      }
    })
    // F2, F3 tự bật isPriceBargainFinder trong employeeConfig mới được chọn tính giá rẻ nhất
    const canPriceBargainFinder = computed(() => {
      const employeeConfig = store.getters['userStore/getEmployeeConfig']
      const isRoleF1 = store.getters['userStore/getRoleMama']
      const { isPriceBargainFinder } = employeeConfig
      return !!(!isRoleF1 && isPriceBargainFinder) || isRoleF1
    })

    const meData = computed(() => store.getters['userStore/getMeData'])
    const isBookerF1 = computed(() => {
      if (isEmpty(meData.value)) return false
      return meData.value?.agency?.id === 1000000 && meData.value?.type === 'BE'
    })

    function isDisableCheckAllTrip(itineraries) {
      if (isEmpty(getBookingData.value) || isBookerF1.value) return false
      if (!getBookingData.value.source.includes('1S')) return false
      return itineraries.some(trip => trip.some(segment => ['HL'].includes(segment.status)))
    }

    // disable hành trình HL không cho F2, F3 chọn để tính giá
    function isDisabledHLSegment(segment) {
      if (isEmpty(getBookingData.value) || isBookerF1.value) return false
      if (!getBookingData.value.source.includes('1S')) return false
      return ['HL'].includes(segment.status)
    }

    return {
      getBookingData,
      paxListsData,
      paxListsColumns,
      vSelectPaxTypeRef,

      formatCurrency,
      submitRecalculatePrice,
      convertISODateTime,
      indexSelectedFlightToRecalculatePrice,

      itinerariesToggleAll,
      itinerariesAllSelected,
      isRetain,
      tourCode,
      platingAirline,
      accountCode,
      allPaxsSelected,
      handleShowModal,
      handleHideModal,

      handlePaxsSelectAll,
      isChecked,
      chooseItem,
      upperCaseFormatter,
      isBargainFinder,
      convertShortTrip,

      // for show price
      newPrice,
      prePayload,
      submitSaveRecalculatePrice,
      showCalculatePriceLoading,
      conversionId,
      conversionTimeout,
      cleanConversion,

      canPriceBargainFinder,

      isDisabledHLSegment,
      isDisableCheckAllTrip,
      isErrorPlatingAirlineRequired,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>

<style lang="scss" scoped>
.margin-checkbox-label::v-deep {
  .custom-control-label {
    // vertical-align: center !important;
    margin-top: 2px !important;
  }
}
.custom-v-select-pax-type::v-deep {
.v-select {
  .vs__dropdown-toggle {
    border: 0;
    box-shadow: none;
  }
}
}
</style>
