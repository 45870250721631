import { useToast } from 'vue-toastification/composition'
import { computed } from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import groupBy from 'lodash/groupBy'
import { v4 as uuidv4 } from 'uuid'

import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useBookingHandle from '@flightv2/useBookingHandle'

export default function useReservationDetailBookingHandle() {
  const { getClassBookingInfoTrip, getSortTripBySegment } = useBookingHandle()

  const TIME_LIMIT_REQUEST_SEARCH_ID = 120

  const RESERVATION_APP_STORE_MODULE_NAME = 'app-reservation'
  const MODIFY_FLIGHT_APP_STORE_MODULE_NAME = 'app-modify-flight'

  const toast = useToast()
  const intervalIDs = []

  function clearIntervalIDs() {
    if (!isEmpty(intervalIDs)) {
      intervalIDs.forEach(id => {
        clearInterval(id)
      })
    }
  }

  async function searchFlightv2(payload) {
    // if (payload && payload.airlines?.length === 0) {
    //   delete payload.airlines
    // }

    if (payload && payload.type === 'OW') {
      delete payload.flights[0].returnDate
    }

    if (payload && payload.type === 'RT' && payload.flights.length > 1) {
      payload.flights = payload.flights.slice(0, 1)
    }

    const { adult, child, infant } = payload

    try {
      const { data: searchId } = await store.dispatch(
        `${RESERVATION_APP_STORE_MODULE_NAME}/searchFlight`,
        payload,
      )
      let lastLengthResources = 0
      const filterAirlines = []
      const filterStopPoints = []
      const filterTransitPoints = []
      const filterDurations = []
      const filterPrices = []
      let timeCounter = 0
      const promisesArr = []
      let domestic = null
      const intervalID = setInterval(async () => {
        try {
          const res = await store.dispatch(
            `${RESERVATION_APP_STORE_MODULE_NAME}/getFlightSearchOperation`,
            searchId,
          )
          const { resources, completedJobs, numberOfJobs } = res.data
          if (resources.length > lastLengthResources) {
            const arrayDiff = resources.slice(
              lastLengthResources,
              resources.length,
            )
            arrayDiff.forEach(async resId => {
              store.dispatch(
                `${RESERVATION_APP_STORE_MODULE_NAME}/setLoading`,
                true,
              )
              promisesArr.push(
                store
                  .dispatch(
                    `${RESERVATION_APP_STORE_MODULE_NAME}/getResourceItinerary`,
                    resId,
                  )
                  .then(resResourceID => {
                    if (
                      resResourceID.data.trips.length
                      && !filterAirlines.find(
                        item => item.value === resResourceID.data.airline,
                      )
                    ) {
                      filterAirlines.push({
                        value: resResourceID.data.airline,
                        text: store.getters[
                          'globalConfig/getAirlineNameByCode'
                        ](resResourceID.data.airline),
                      })
                    }
                    resResourceID.data.trips.forEach(trip => {
                      if (domestic === null) {
                        domestic = trip.domestic
                      }
                      trip.fareOptions = trip.fareOptions.map(fareItem => {
                        const afterMultiple = {
                          fareAdult: fareItem.fareAdult * adult,
                          taxAdult: fareItem.taxAdult * adult,
                          surchargeAdult: fareItem.surchargeAdult * adult,
                          discountAdult: fareItem.discountAdult * adult,
                          totalAdult: fareItem.totalAdult * adult,
                          fareChild: fareItem.fareChild * child,
                          taxChild: fareItem.taxChild * child,
                          surchargeChild: fareItem.surchargeChild * child,
                          discountChild: fareItem.discountChild * child,
                          totalChild: fareItem.totalChild * child,
                          fareInfant: fareItem.fareInfant * infant,
                          taxInfant: fareItem.taxInfant * infant,
                          surchargeInfant: fareItem.surchargeInfant * infant,
                          discountInfant: fareItem.discountInfant * infant,
                          totalInfant: fareItem.totalInfant * infant,
                          total: fareItem.totalAdult * adult + fareItem.totalChild * child + fareItem.totalInfant * infant,
                        }
                        filterPrices.push(afterMultiple.total)
                        return {
                          ...fareItem,
                          afterMultiple,
                        }
                      })

                      if (!filterDurations.includes(trip.duration)) {
                        filterDurations.push(trip.duration)
                      }

                      const numberStop = trip.legs - 1
                      if (
                        !filterStopPoints.find(
                          item => item.value === numberStop,
                        )
                      ) {
                        if (numberStop === 0) {
                          filterStopPoints.push({ text: 'Direct', value: 0 })
                        } else if (numberStop > 0) {
                          const transitPointsArray = trip.stopPoint.split(' | ')

                          const transitPointsToPush = transitPointsArray.map(
                            item => {
                              const getAirportOfItem = store.getters[
                                `${RESERVATION_APP_STORE_MODULE_NAME}/getAirportByAirportCode`
                              ](item)
                              return {
                                text:
                                  getAirportOfItem.city
                                  || getAirportOfItem.name,
                                value: item,
                              }
                            },
                          )
                          transitPointsToPush.forEach(item => filterTransitPoints.push(item))
                          filterStopPoints.push({
                            text: `${numberStop} transit${
                              numberStop > 1 ? 's' : ''
                            }`,
                            value: numberStop,
                          })
                        }
                      }
                    })
                    store.dispatch(
                      `${RESERVATION_APP_STORE_MODULE_NAME}/pushResultSearchFlight`,
                      resResourceID.data,
                    )
                  })
                  .catch(error => {
                    console.error(error)
                  })
                  .finally(() => {
                    store.dispatch(
                      `${RESERVATION_APP_STORE_MODULE_NAME}/setLoading`,
                      false,
                    )
                  }),
              )
            })
          }
          lastLengthResources = resources.length
          let percentJobs = (completedJobs / numberOfJobs) * 100
          const processValue = store.getters[
            `${RESERVATION_APP_STORE_MODULE_NAME}/getProgressValue`
          ]
          if (processValue && processValue >= percentJobs) {
            percentJobs = processValue + 1
          }
          store.dispatch(
            `${RESERVATION_APP_STORE_MODULE_NAME}/setProgressValue`,
            percentJobs < 100 ? percentJobs : 99,
          )
          timeCounter += 1
          if (
            res.data.status === 'Succeeded'
            || timeCounter >= TIME_LIMIT_REQUEST_SEARCH_ID
          ) {
            Promise.all(promisesArr).then(() => {
              store.dispatch(
                `${RESERVATION_APP_STORE_MODULE_NAME}/setIsDomestic`,
                domestic,
              )
              // store.dispatch(`${RESERVATION_APP_STORE_MODULE_NAME}/setFilterAirlines`, filterAirlines)
              // store.dispatch(`${RESERVATION_APP_STORE_MODULE_NAME}/setFilterPrices`, [Math.min(...filterPrices), Math.max(...filterPrices)])
              // store.dispatch(`${RESERVATION_APP_STORE_MODULE_NAME}/setFilterStopPoints`, filterStopPoints)
              // store.dispatch(`${RESERVATION_APP_STORE_MODULE_NAME}/setFilterTransitPoints`, filterTransitPoints)
              // store.dispatch(`${RESERVATION_APP_STORE_MODULE_NAME}/setFilterDurations`, [Math.max(...filterDurations), Math.min(...filterDurations)])
            })

            clearIntervalIDs()
            store.dispatch(
              `${RESERVATION_APP_STORE_MODULE_NAME}/setProgressValue`,
              100,
            )
          }
        } catch (e) {
          console.error(e)
          clearIntervalIDs()
        }
      }, 1000)
      intervalIDs.push(intervalID)
    } catch (error) {
      console.error({ error })
    }
  }

  function fetchAirportGroup() {
    return new Promise((resolve, reject) => {
      store
        .dispatch(`${RESERVATION_APP_STORE_MODULE_NAME}/fetchAirportGroup`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  function fetchAirports(payload) {
    return new Promise((resolve, reject) => {
      store
        .dispatch(`${RESERVATION_APP_STORE_MODULE_NAME}/fetchAirports`, {
          searchText: payload,
        })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching booking list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
          reject(error)
        })
    })
  }

  async function searchFlightForModify(payload) {
    const { adult, child, infant } = payload

    try {
      store.dispatch(`${RESERVATION_APP_STORE_MODULE_NAME}/setLoading`, true)
      const res = await store.dispatch(`${RESERVATION_APP_STORE_MODULE_NAME}/searchFlightForModify`, payload)

      // FIXME: modify trip by itinerary
      const mapResultTrip = []
      const listsItineraryId = res.trips.every(trip => trip.itineraryId)
        ? groupBy(res.trips, item => item.itineraryId)
        : { 1: res.trips }

      for (const key in listsItineraryId) {
        if (Object.hasOwnProperty.call(listsItineraryId, key)) {
          mapResultTrip.push({
            source: res.source,
            itineraryId: Number(key),
            trips: listsItineraryId[key],
          })
        }
      }

      mapResultTrip.forEach((data, index) => {
        const filterStopPoints = []
        const filterTransitPoints = []
        const filterDurations = []
        const filterPrices = []
        let domestic = null

        data.trips.forEach(trip => {
          if (domestic === null) {
            domestic = trip.domestic
          }

          trip.id = uuidv4()

          trip.fareOptions = trip.fareOptions.map(fareItem => {
            const afterMultiple = {
              fareAdult: fareItem.fareAdult * adult,
              taxAdult: fareItem.taxAdult * adult,
              surchargeAdult: fareItem.surchargeAdult * adult,
              discountAdult: fareItem.discountAdult * adult,
              totalAdult: fareItem.totalAdult * adult,
              fareChild: fareItem.fareChild * child,
              taxChild: fareItem.taxChild * child,
              surchargeChild: fareItem.surchargeChild * child,
              discountChild: fareItem.discountChild * child,
              totalChild: fareItem.totalChild * child,
              fareInfant: fareItem.fareInfant * infant,
              taxInfant: fareItem.taxInfant * infant,
              surchargeInfant: fareItem.surchargeInfant * infant,
              discountInfant: fareItem.discountInfant * infant,
              totalInfant: fareItem.totalInfant * infant,
              total:
                fareItem.totalAdult * adult
                + fareItem.totalChild * child
                + fareItem.totalInfant * infant,
            }
            filterPrices.push(afterMultiple.total)
            return {
              ...fareItem,
              afterMultiple,
            }
          })

          if (!filterDurations.includes(trip.duration)) {
            filterDurations.push(trip.duration)
          }
          const numberStop = trip.legs - 1
          if (!filterStopPoints.find(item => item.value === numberStop)) {
            if (numberStop === 0) {
              filterStopPoints.push({ text: 'Direct', value: 0 })
            } else if (numberStop > 0) {
              const transitPointsArray = trip.stopPoint.split(' | ')

              const transitPointsToPush = transitPointsArray.map(async item => {
                let getAirportOfItem = null
                getAirportOfItem = store.getters[`${RESERVATION_APP_STORE_MODULE_NAME}/getAirportByAirportCode`](item)

                if (!getAirportOfItem) {
                  await fetchAirports(item)
                  getAirportOfItem = store.getters[`${RESERVATION_APP_STORE_MODULE_NAME}/getAirportByAirportCode`](item)
                }

                return {
                  text: getAirportOfItem?.city || getAirportOfItem?.name || '',
                  value: item,
                }
              })
              transitPointsToPush.forEach(item => filterTransitPoints.push(item))
              filterStopPoints.push({
                text: `${numberStop} transit${numberStop > 1 ? 's' : ''}`,
                value: numberStop,
              })
            }
          }
        })

        store.dispatch(`${RESERVATION_APP_STORE_MODULE_NAME}/pushResultSearchFlight`, data)
        store.dispatch(`${RESERVATION_APP_STORE_MODULE_NAME}/setIsDomestic`, domestic)

        // store.dispatch(`${RESERVATION_APP_STORE_MODULE_NAME}/setFilterAirlines`, filterAirlines)
        // store.dispatch(`${RESERVATION_APP_STORE_MODULE_NAME}/setFilterPrices`, [Math.min(...filterPrices), Math.max(...filterPrices)])
        // store.dispatch(`${RESERVATION_APP_STORE_MODULE_NAME}/setFilterStopPoints`, filterStopPoints)
        // store.dispatch(`${RESERVATION_APP_STORE_MODULE_NAME}/setFilterTransitPoints`, filterTransitPoints)
        // store.dispatch(`${RESERVATION_APP_STORE_MODULE_NAME}/setFilterDurations`, [Math.max(...filterDurations), Math.min(...filterDurations)])
      })
    } catch (error) {
      if (error.message.includes('500')) {
        store.dispatch(
          `${RESERVATION_APP_STORE_MODULE_NAME}/pushResultSearchFlight`,
          {
            itineraryId: 1,
            source: 'VJ',
            trips: [],
          },
        )
      }
      console.error({ error })
    } finally {
      store.dispatch(`${RESERVATION_APP_STORE_MODULE_NAME}/setLoading`, false)
    }
  }

  function searchClassBooking(payload) {
    return new Promise((resolve, reject) => {
      store
        .dispatch(
          `${RESERVATION_APP_STORE_MODULE_NAME}/searchClassBooking`,
          payload,
        )
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const getAirportByAirportCode = airportCode => store.getters[`${RESERVATION_APP_STORE_MODULE_NAME}/getAirportByAirportCode`](airportCode)

  const isAddFlights = computed(
    () => store.getters[`${RESERVATION_APP_STORE_MODULE_NAME}/getIsAddFlights`],
  )

  const getLoading = computed(
    () => store.getters[`${RESERVATION_APP_STORE_MODULE_NAME}/getLoading`],
  )

  const processValue = computed(
    () => store.getters[`${RESERVATION_APP_STORE_MODULE_NAME}/getProgressValue`],
  )

  const tabIndex = computed(
    () => store.getters[`${RESERVATION_APP_STORE_MODULE_NAME}/getTabIndex`],
  )

  const isDomestic = computed(
    () => store.getters[`${RESERVATION_APP_STORE_MODULE_NAME}/getIsDomestic`],
  )

  const getAirportGroup = computed(
    () => store.getters[`${RESERVATION_APP_STORE_MODULE_NAME}/getAirportGroup`],
  )

  const flightSearch = computed(
    () => store.getters[`${RESERVATION_APP_STORE_MODULE_NAME}/getSearchFlight`],
  )

  const getSearchFlightArray = computed(
    () => store.getters[`${RESERVATION_APP_STORE_MODULE_NAME}/getSearchFlightArray`],
  )

  const resultSearchFlight = computed(
    () => store.getters[`${RESERVATION_APP_STORE_MODULE_NAME}/getResultSearchFlight`],
  )

  const tempSelectedTrips = computed(
    () => store.getters[`${RESERVATION_APP_STORE_MODULE_NAME}/getSelectedTrip`],
  )

  const selectedFlightIndex = computed(
    () => store.getters[`${RESERVATION_APP_STORE_MODULE_NAME}/getSelectedFlightIndex`],
  )

  const itinerariesByIndexSelected = computed(
    () => store.getters[`${RESERVATION_APP_STORE_MODULE_NAME}/getItinerariesByIndexSelected`],
  )

  const getAirlineNameByCode = code => store.getters['globalConfig/getAirlineNameByCode'](code) || code

  function convertToISO8601Date(dateString) {
    const inputDate = new Date(dateString)
    return inputDate.toISOString() || null
  }

  const selectedTrips = computed(
    () => store.getters['app-modify-flight/getSelectedTrips'],
  )

  return {
    fetchAirportGroup,
    fetchAirports,
    searchFlightv2,
    searchClassBooking,
    searchFlightForModify,
    getAirlineNameByCode,
    getClassBookingInfoTrip,
    convertToISO8601Date,
    getAirportByAirportCode,
    getSortTripBySegment,

    isAddFlights,
    getLoading,
    processValue,
    getAirportGroup,
    tabIndex,
    isDomestic,
    tempSelectedTrips,
    resultSearchFlight,
    getSearchFlightArray,
    flightSearch,
    selectedFlightIndex,
    itinerariesByIndexSelected,

    MODIFY_FLIGHT_APP_STORE_MODULE_NAME,
    selectedTrips,
  }
}
