<template>
  <!-- SECTION modal-modify-class-booking-add-flight-confirm -->
  <b-modal
    :id="`modal-modify-class-booking-add-flight-confirm${isCheapest}`"
    :title="$t('reservation.historyActions.MODIFY_ADD_FLIGHT')"
    title-class="text-heading-3 text-airline font-weight-bolder"
    centered
    :no-close-on-backdrop="true"
    size="lg"
    @show="handleShowModal"
    @hide="handleHideModal"
  >
    <template #modal-footer="{close}">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        size="md"
        variant="outline-secondary"
        class="center rounded-pill width-100 mr-2"
        @click="close()"
      >
        {{ $t('reservation.close') }}
      </b-button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="btn-gradient mt-lg-25 border-0"
        :disabled="!isConfirm"
        pill
        @click="handleClick(selectedTrips)"
      >
        <span class="align-middle">
          Thêm chuyến bay
        </span>
      </b-button>
    </template>

    <div class="text-heading-5">
      {{ $t('reservation.confirmAddFlight') }}
    </div>

    <div class="text-heading-4 text-success mt-2 mb-25 ml-1">
      {{ $t('reservation.newFlight') }}:
    </div>

    <IAmOverlay
      :loading="isEmpty(tripsShow)"
      :spinner-variant="'info'"
    >
      <b-card
        v-for="(itinerary, index) in tripsShow"
        :key="index"
        class="my-50 border-success shadow-lg mx-3"
        body-class="py-1"
      >
        <div
          v-for="(segment, indexSegment) in itinerary"
          :key="indexSegment"
        >
          <!-- {{ convertShortTrip(segment) }} -->
          <div class="d-flex flex-wrap align-items-center">
            <div class="font-weight-bolder">
              <span class="mr-50 text-nowrap">{{ indexSegment + 1 }}</span>
              <span class="mr-50 text-nowrap">{{ segment.airline }}{{ segment.flightNumber }}</span>
              <span class="mr-50 text-nowrap">{{ segment.bookingClass.code }}</span>
              <span class="mr-50 text-nowrap">{{ convertISODateTime( segment.departureDate, segment.departureTimezone).dayMonth }}</span>
              <span class="mr-50 text-nowrap">{{ segment.departure }}{{ segment.arrival }}</span>
              <!-- <span class="mr-50 text-nowrap">{{ statusSegment[index][indexSegment] }}</span> -->
              <span class="mr-50 text-nowrap">{{ segment.bookingClass.status }}</span>
              <span>{{ convertISODateTime( segment.departureDate, segment.departureTimezone).hourMin }} {{ convertISODateTime(segment.arrivalDate, segment.arrivalTimezone).hourMin }}</span>
            </div>
          </div>
        </div>
      </b-card>

      <div class="margin-checkbox-label d-flex-center mt-3">
        <b-form-checkbox
          v-model="isConfirm"
          :state="isConfirm === true ? true : false"
        >
          <span>{{ $t('reservation.iAmConfirm') }}</span>
        </b-form-checkbox>
      </div>
    </IAmOverlay>
  </b-modal>
</template>
<script>
import {
  BCard,
  BButton,
  BModal,
  BFormCheckbox,
} from 'bootstrap-vue'
import { toRefs, ref, computed } from '@vue/composition-api'
import { groupBy, isEmpty } from 'lodash-es'
import Vue from 'vue'

import router from '@/router'
import store from '@/store'
import { getUserData } from '@/api/auth/utils'
import IAmOverlay from '@/components/IAmOverlay.vue'
import { resolveTitle } from '@/constants/selectOptions'

import {
  convertISODateTime,
  formatCurrency,
  convertShortTrip,
} from '@core/utils/filter'

import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BFormCheckbox,
    IAmOverlay,
  },
  props: {
    selectedTrips: {
      type: Array,
      default: () => [],
    },
    statusSegment: {
      type: Array,
      default: () => [],
    },
    bookingData: {
      type: Object,
      default: () => {},
    },
    isCheapest: {
      type: String,
      default: '',
    },
  },
  setup(props, { root, emit }) {
    const { toastError, toastSuccess } = useToast()
    const {
      selectedTrips: trips, bookingData: oldData, statusSegment,
    } = toRefs(props)
    const { addFlights } = useReservationHandle()

    const loading = ref(false)
    const isConfirm = ref(false)
    const tripsShow = ref(null)

    function getPassengerTitle(passenger) {
      if (passenger.title) {
        return resolveTitle(passenger.title) || ''
      }

      const lastOfFirstName = passenger.firstName.split(' ').splice(-1)[0]
      if (resolveTitle(lastOfFirstName)) {
        return resolveTitle(lastOfFirstName)
      }

      return ''
    }

    function getFareBreakdowns(bookingData) {
      const result = []
      const passengerByPaxType = groupBy(bookingData.paxLists, 'paxType')

      for (const paxType in passengerByPaxType) {
        if (Object.prototype.hasOwnProperty.call(passengerByPaxType, paxType)) {
          result.push({
            paxType,
            netFare: 0,
            discountAmount: 0,
            tax: 0,
            total: 0,
            fareValue: '',
          })
        }
      }
      return result
    }

    function handleAddFlight(payload) {
      store.dispatch('app/setLoading', true)

      addFlights(payload, false)
        .then(res => {
          // router.go(-1)

          emit('update:shorten-text', res)

          root.$bvModal.hide('modal-modify-class-booking-add-flight-confirm')

          toastSuccess({
            title: 'messagesList.success',
            content: 'Thêm hành trình thành công',
          })
        })
        .catch(error => {
          const errorMessageList = computed(() => store.getters['globalConfig/getErrorMessage'])
          console.error({ error })
          toastError({
            title: 'messagesList.error',
            content: 'Có lỗi xảy ra khi thực hiện thay đổi hành trình.',
          })
          if (typeof error?.response?.data === 'object' && error?.response?.data?.message) {
            const textShow = errorMessageList.value.find(i => i.key === error.response.data.message) || errorMessageList.value.find(err => JSON.stringify(error.response.data.message).includes(err.key))
            Vue.swal({
              title: 'Có lỗi xảy ra!',
              text: textShow ? textShow.value : `Vui lòng kiểm tra mã lỗi: ${typeof error.response.data.message === 'object' ? JSON.stringify(error.response.data.message) : error.response.data.message}`,
              icon: 'error',
              showCancelButton: true,
              showConfirmButton: true,
              confirmButtonText: 'Copy lỗi',
              customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                navigator.clipboard.writeText(`[${error?.response?.status}] ${JSON.stringify(error?.response?.data)} | ${error?.request?.responseURL}: ${JSON.stringify(error?.config?.data)}`)
                Vue.swal({
                  icon: 'success',
                  title: 'Đã copy!',
                  customClass: { confirmButton: 'btn btn-success' },
                })
              }
            })
          }
        })
        .finally(() => {
          store.dispatch('app/setLoading', false)
        })
    }

    const handleClick = trips => {
      const bookingData = oldData.value

      const payload = {
        contact: getUserData().employeeData.id,
        airline: bookingData.source.includes('1S') ? 'VN' : ['VJ', 'VZ'].includes(bookingData.source) ? 'VJ' : bookingData.source,
        source: bookingData.source,
        agencyCode: bookingData.agency,
        paxCode: bookingData.paxContact.code,
        pnrNumber: bookingData.bookingCode,
        paxList: bookingData.paxLists.map(passenger => ({
          firstName: passenger.firstName,
          lastName: passenger.lastName,
          title: getPassengerTitle(passenger) || '',
          paxType: passenger.paxType,
          birthday: passenger?.birthday || null,
          paxId: passenger.paxId,
          parentPaxId: passenger?.parentPaxId || '',
          PaxDocuments: passenger.document,
          eTicketNumber: '',
          pricingInfo: passenger.pricingInfo,
        })),
        itineraries: trips.map(trip => ({
          source: bookingData.source,
          clientId: bookingData?.clientId || '',
          fareBreakdowns: getFareBreakdowns(bookingData),
          segments: trip.map((segment, indexSegment) => ({
            leg: indexSegment + 1,
            segmentId: '',
            airline: bookingData.source.includes('1S') ? 'VN' : ['VJ', 'VZ'].includes(bookingData.source) ? 'VJ' : bookingData.source,
            departure: segment.departure,
            arrival: segment.arrival,
            departureTime: convertISODateTime(segment.departureDate, segment.departureTimezone).ISOdatetime,
            arrivalTime: convertISODateTime(segment.arrivalDate, segment.arrivalTimezone).ISOdatetime,
            flightNumber: String(segment.flightNumber),
            fareType: '',
            fareBasisCode: '',
            bookingClass: segment.bookingClass.code,
            groupClass: '',
            marriageGrp: '',
            segmentValue: '',
            status: segment.bookingClass.status, // statusSegment.value[indexTrip][indexSegment],
          })),
        })),
      }

      handleAddFlight(payload)
    }

    function handleBack() {
      router.go(-1)
    }

    function handleShowModal() {
      tripsShow.value = props.selectedTrips
    }

    function handleHideModal() {
      isConfirm.value = false
      tripsShow.value = null
    }

    return {
      trips,
      loading,
      isEmpty,
      isConfirm,
      tripsShow,

      handleBack,
      handleClick,
      formatCurrency,
      handleHideModal,
      handleShowModal,
      convertISODateTime,
      convertShortTrip,
    }
  },
}
</script>
  <style lang="scss" scoped>
  #footer ::v-deep {
    position: sticky;
    bottom: 10px;
    z-index: 10;

    .bg-transparent {
      background: rgb(255, 255, 255);
      backdrop-filter: blur(80px);
    }
  }
  </style>

  <style lang="scss" scoped>
  .margin-checkbox-label::v-deep {
    .custom-control-label {
      margin-bottom: 1px !important;
    }
  }
  </style>
