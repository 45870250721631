var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isShowWarningQH ? _c('b-alert', {
    staticClass: "m-25 fw-700 p-50 text-center",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" \"Vé đã mua hành lý 1 chặng, chặng còn lại phải mua gói hành lý khác gói đã mua trước đó, nếu cần mua gói tương tự vui lòng chat Booker.\" ")]) : _vm._e(), !_vm.dataMapAncillaryServices.length ? _c('div', {
    staticClass: "px-1 pb-1"
  }, [_c('em', [_vm._v(_vm._s(_vm.$t('reservation.noAddons')))])]) : _c('div', {
    staticClass: "p-0"
  }, [_c('b-table', {
    staticClass: "mb-0",
    attrs: {
      "centered": "",
      "bordered": "",
      "responsive": "",
      "small": "",
      "hover": "",
      "thead-class": "text-center fw-700 text-nowrap",
      "tbody-class": "text-center text-nowrap",
      "show-empty": "",
      "empty-text": _vm.$t('reservation.noAddons'),
      "fields": _vm.tableColumns,
      "items": _vm.dataMapAncillaryServices
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column, indexColumn) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: indexColumn
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "cell(passenger)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [['1S', '1S_CTRL'].includes(_vm.reservationsData.source) ? _c('small', {
          staticClass: "text-warning font-weight-bolder"
        }, [_vm._v(_vm._s(item.passenger.paxId))]) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(item.passenger.lastName) + " / " + _vm._s(item.passenger.firstName) + " " + _vm._s(item.passenger.title || '') + " ")])];
      }
    }, {
      key: "cell(itinerary)",
      fn: function fn(_ref2) {
        var _item$segments$, _item$segments$$depar, _item$segments$2, _item$segments$2$arri;
        var item = _ref2.item;
        return [['TH'].includes(_vm.bookingSource) ? _c('div', [_vm._v(" " + _vm._s(item.segments) + " ")]) : !_vm.isEmpty(item.segments) && item.segments.length < 2 ? _c('div', [_vm._v(" " + _vm._s((_item$segments$ = item.segments[0]) === null || _item$segments$ === void 0 ? void 0 : (_item$segments$$depar = _item$segments$.departure) === null || _item$segments$$depar === void 0 ? void 0 : _item$segments$$depar.iataCode) + " - " + _vm._s((_item$segments$2 = item.segments[0]) === null || _item$segments$2 === void 0 ? void 0 : (_item$segments$2$arri = _item$segments$2.arrival) === null || _item$segments$2$arri === void 0 ? void 0 : _item$segments$2$arri.iataCode) + " ")]) : !_vm.isEmpty(item.segments) && item.segments.length > 1 ? _c('div', _vm._l(item.segments, function (segment, indexSegment) {
          var _segment$departure, _item$segments, _segment$departure2, _segment$arrival;
          return _c('span', {
            key: indexSegment
          }, [_vm._v(" " + _vm._s(indexSegment === 0 ? "".concat(segment === null || segment === void 0 ? void 0 : (_segment$departure = segment.departure) === null || _segment$departure === void 0 ? void 0 : _segment$departure.iataCode) : '') + " " + _vm._s(indexSegment !== 0 && indexSegment !== (item === null || item === void 0 ? void 0 : (_item$segments = item.segments) === null || _item$segments === void 0 ? void 0 : _item$segments.length) - 1 ? " - ".concat(segment === null || segment === void 0 ? void 0 : (_segment$departure2 = segment.departure) === null || _segment$departure2 === void 0 ? void 0 : _segment$departure2.iataCode) : " - ".concat(segment === null || segment === void 0 ? void 0 : (_segment$arrival = segment.arrival) === null || _segment$arrival === void 0 ? void 0 : _segment$arrival.iataCode)) + " ")]);
        }), 0) : _vm._e()];
      }
    }, {
      key: "cell(services)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', [_vm._v(_vm._s(_vm.$t("reservation.".concat(['BAGGAGE', 'SEAT'].includes(item.serviceType) ? item.serviceType.toLowerCase() : 'otherSsr'))))])];
      }
    }, {
      key: "cell(amount)",
      fn: function fn(_ref4) {
        var _item$serviceInfo, _item$serviceInfo2;
        var item = _ref4.item;
        return [item.serviceType === 'SEAT' ? _c('span', [_vm._v(" " + _vm._s(item.pdcSeat) + " ")]) : _vm._e(), item.serviceType === 'BAGGAGE' ? _c('div', [['VJ'].includes(item.airline) ? _c('span', [_vm._v(" " + _vm._s(_vm.resolveSsrNameByCode(item.serviceInfo.description)) + " x" + _vm._s(item.numberOfItems) + " ")]) : ['TR'].includes(_vm.reservationsData.source) ? _c('span', [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : (_item$serviceInfo = item.serviceInfo) === null || _item$serviceInfo === void 0 ? void 0 : _item$serviceInfo.description) || item.serviceCode) + " x" + _vm._s(item.numberOfItems) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.resolveSsrNameByCode(['VN1A_MT', 'VN1A', 'TH'].includes(_vm.reservationsData.source) ? item.serviceCode : item.serviceSubCode)) + " x" + _vm._s(item.numberOfItems) + " ")])]) : _vm._e(), item.isOtherService ? _c('div', [['VJ'].includes(_vm.reservationsData.source) ? _c('span', [_vm._v(" " + _vm._s(_vm.resolveSsrNameByCode(item.serviceInfo.description)) + " ")]) : ['TR'].includes(_vm.reservationsData.source) ? _c('span', [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : (_item$serviceInfo2 = item.serviceInfo) === null || _item$serviceInfo2 === void 0 ? void 0 : _item$serviceInfo2.description) || item.serviceCode) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.resolveSsrNameByCode(item.serviceSubCode || item.serviceCode)) + " ")])]) : _vm._e()];
      }
    }, {
      key: "cell(status)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [['VN'].includes(item.airline) ? _c('span', {
          staticClass: "font-weight-bolder font-small-4",
          class: item.status === 'HI' ? 'text-success' : item.status === 'HD' ? 'text-warning' : 'text-dark'
        }, [_vm._v(_vm._s(_vm.STATUS_NAME_ANCILLARY[item.status] || item.status))]) : _vm._e()];
      }
    }, {
      key: "cell(total)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', {
          staticClass: "fw-700"
        }, [_vm._v(_vm._s(_vm.formatCurrency(item.totalPrice)))])];
      }
    }, {
      key: "cell(paymentStatus)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', [_c('span', {
          class: "fw-700 ".concat(item.isPaid ? 'text-success' : 'text-warning')
        }, [_vm._v(" " + _vm._s(item.isPaid ? 'Đã thanh toán' : 'Chưa thanh toán') + " ")])])];
      }
    }, {
      key: "cell(action)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [!_vm.isHideFunctionButton ? _c('b-row', {
          staticClass: "mx-0 d-flex-center gap-2",
          staticStyle: {
            "min-width": "100px"
          }
        }, [_vm.fnAncillary[item.serviceType] && _vm.fnAncillary[item.serviceType].edit && _vm.disableBagVj2k(item) ? _c('b-button', {
          staticClass: "p-50 rounded-circle bg-light-info",
          attrs: {
            "variant": "flat-info"
          },
          on: {
            "click": function click($event) {
              return _vm.openModalUpdateBaggage(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "Edit3Icon",
            "size": "18"
          }
        })], 1) : _vm._e(), item && _vm.fnAncillary[['MEAL'].includes(item.serviceType) ? 'OTHER' : item.serviceType] && _vm.fnAncillary[['MEAL'].includes(item.serviceType) ? 'OTHER' : item.serviceType].delete ? _c('b-button', {
          staticClass: "p-50 rounded-circle bg-light-danger",
          attrs: {
            "variant": "flat-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.handleRemoveSsrInLine(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "MinusIcon",
            "size": "16"
          }
        })], 1) : _vm._e()], 1) : _vm._e()];
      }
    }], null, true)
  }), _c('b-row', {
    staticClass: "d-flex justify-content-between align-items-end fw-700"
  }, [_vm.isWarningWeightBaggageAK ? _c('b-col', {
    staticClass: "my-50",
    attrs: {
      "cols": "12",
      "lg": "8"
    }
  }, [_c('b-alert', {
    staticClass: "my-0 fw-700 mx-1 p-50 text-center",
    staticStyle: {
      "min-width": "150px"
    },
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" Trọng lượng tối đa của hành lý là 32kg mỗi kiện ")])], 1) : _vm._e(), _vm.isShowWarningBaggageAddonVJ ? _c('b-col', {
    staticClass: "my-50",
    attrs: {
      "cols": "12",
      "lg": "8"
    }
  }, [_c('b-alert', {
    staticClass: "my-0 fw-700 mx-1 py-50 px-2 text-left",
    staticStyle: {
      "min-width": "150px"
    },
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" Gỡ bỏ hành lý sau khi thanh toán có thể bị hãng truy thu. Đại lý vui lòng tự chịu trách nhiệm và cân nhắc trước khi thao tác. ")])], 1) : _vm._e(), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-end py-50"
  }, [_c('div', {
    staticClass: "d-flex flex-nowrap mr-1"
  }, [_c('span', {
    staticClass: "mr-1 font-medium-2 text-nowrap text-danger"
  }, [_vm._v(_vm._s(_vm.$t('flight.total')) + ": "), _c('span', {
    staticClass: "text-20px"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalDataMapAncillaryServices)) + " ")])]), _c('div', {
    staticClass: "text-nowrap mr-md-0 my-auto"
  }, [_c('feather-icon', {
    staticClass: "text-warning mr-25",
    attrs: {
      "icon": "InfoIcon"
    }
  }), _c('span', {
    staticClass: "text-nowrap text-italic text-warning font-small-3"
  }, [_vm._v(_vm._s(_vm.$t('flight.fareText')))])], 1)])])], 1)], 1), _c('ModalBaggageFirstHandle'), _vm.dataListToUpdate ? _c('ModalBaggageUpdate', {
    attrs: {
      "reservations-data": _vm.reservationsData,
      "data-pax-in-trip": _vm.dataListToUpdate
    }
  }) : _vm._e(), _c('ModalSeatDetails', {
    attrs: {
      "reservations-data": _vm.reservationsData
    }
  }), _c('ModalOtherFirstHandle', {
    attrs: {
      "reservations-data": _vm.reservationsData
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }